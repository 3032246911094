<template>
    <div class="header-link-list mx-4 lg:mx-0 px-2 pt-6 pb-3 lg:p-0 border-t border-blue-lighter lg:border-0">
        <p class="header-link-list__title font-medium text-base lg:text-lg text-black uppercase">{{ links.heading }}</p>

        <template v-for="(featuredContent, key) in links.featuredContent">
            <div :key="key" class="flex flex-col mt-6">
                <img :src="featuredContent.image.url" :alt="featuredContent.image.title">
                <p v-html="featuredContent.text" class="header-link-list__featured-text font-light text-sm text-gray-700 mt-3"/>
                <a
                    :href="featuredContent.cta.url"
                    :target="featuredContent.cta.target"
                    class="header-link-list__featured-link text-xs underline text-black mt-3"
                >{{ featuredContent.cta.label }}</a>
            </div>
        </template>

        <ul class="list-none p-0">
            <li
                v-for="(link, key) in links.ctas"
                :class="{'pl-5': link.position.value === 'right'}"
                class="mb-3 lg:mb-5"
                :key="key"
            >
                <a
                    :href="link.cta.url"
                    :target="link.cta.target"
                    :class="{'header-link-list__link--indented': link.position.value === 'right'}"
                    class="header-link-list__link flex items-center text-black text-sm lg:text-base"
                >{{ link.cta.label }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "HeaderLinkList",

    props: {
        links: {
            type: Object,
            required: true,
        },
    }
}
</script>

<style scoped lang="scss">
    .header-link-list {
        &__title {
            letter-spacing: 0.14em;
        }

        &__link--indented::before {
            content:'';
            @apply block w-2 h-2 rounded-full bg-blue;
            margin-right: 14px;
        }

        &__featured-text {
            line-height: 1.375rem;
        }

        &__featured-link {
            line-height: 0.8125rem;
        }
    }
</style>
