<template>
    <div class="modal-opener">
        <button class="w-full h-full" @click.prevent.stop="isModalOpened = true">
            <slot name="modal-button-content"></slot>
        </button>

        <modal :is-opened="isModalOpened"
               :has-padding="hasPadding"
               @close="isModalOpened = false">
            <slot name="modal-content"></slot>
        </modal>
    </div>
</template>

<script>
    import Modal from "@/app/components/Modal";

    export default {
        name: "ModalOpener",

        components: { Modal },

        props: {
            hasPadding: { type: Boolean, default: true },
        },

        data() {
            return {
                isModalOpened: false,
            }
        },

        created() {
            document.addEventListener("keyup", this.onKeyUp);
        },
        destroyed() {
            document.removeEventListener("keyup", this.onKeyUp);
        },

        methods: {
            onKeyUp (event) {
                // Escape key
                if (event.keyCode === 27) {
                    this.isModalOpened = false;
                }
            },
        },
    }
</script>

<style scoped>

</style>
