<template>
    <transition name="overlay-fade" mode="out-in">
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: "OverlayFade"
    }
</script>

<style scoped lang="scss">
    .overlay-fade-enter-active, .overlay-fade-leave-active {
        transition: opacity 250ms ease, transform 250ms ease;
    }
    .overlay-fade-enter, .overlay-fade-leave-to {
        opacity: 0;
        transform: translateX(40px);
    }
</style>
