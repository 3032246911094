<template>
    <a
        v-if="isVisible"
        href="#"
        class="back-to-top fixed flex justify-center items-center rounded bg-blue w-8 h-8"
        :aria-label="buttonAriaLabel"
    >
        <span class="Picto_arrow-up2"></span>
    </a>
</template>

<script>
export default {
    name: "BackToTop",

    props: {
        ratio: {
            type: Number,
            default: 3,
        },
        buttonAriaLabel: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isVisible: false,
        }
    },

    computed: {
        isDisplayable() {
            return document.body.clientHeight > this.ratio * window.innerHeight;
        }
    },

    mounted() {
        window.addEventListener('scroll', this.scrollHandler);
    },

    destroyed() {
        window.removeEventListener('scroll', this.scrollHandler);
    },

    methods: {
        scrollHandler() {
            this.isVisible = window.scrollY > window.innerHeight && this.isDisplayable;
        }
    }
}
</script>

<style lang="scss" scoped>
    .back-to-top {
        bottom: 150px;
        right: 10px;
        z-index: 2;
    }
</style>
