<template>
    <transition name="modal-fade">
        <div ref="scrollContainer" v-if="isOpened" class="modal overflow-y-scroll fixed z-10 flex justify-center inset-0 w-screen h-screen" @click="$emit('close')">
            <div class="table py-10 w-full h-full">
                <div class="table-cell align-middle">
                    <div class="modal__container relative w-full h-full md:h-auto bg-white mx-auto"
                         :class="hasPadding ? 'py-2 md:py-6 md:px-4' : null"
                         @click.stop>
                        <button type="button"
                                class="modaal-close z-10"
                                id="modaal-close"
                                aria-label="Close (Press escape to close)"
                                @click="$emit('close')"
                        >
                            <span>Close</span>
                        </button>
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { disablePageScroll, enablePageScroll, addFillGapSelector } from 'scroll-lock';
export default {
        name: "Modal",

        props: {
            isOpened:   { type: Boolean, required: true },
            hasPadding: { type: Boolean, default: true }
        },

        mounted() {
            addFillGapSelector('.BackToTop');
        },

        watch: {
            isOpened(isOpened) {
                if (isOpened) {
                    disablePageScroll(this.$refs.scrollContainer);
                } else {
                    enablePageScroll(this.$refs.scrollContainer);
                }
            },
        },
    }
</script>

<style lang="scss">
    .modal {
        background-color: rgba(0, 0, 0, 0.7);

        &__container {
            max-width: 60rem;
        }
    }

    .modal-fade-enter-active, .modal-fade-leave-active {
        transition: opacity 150ms ease-in-out;
    }
    .modal-fade-enter, .modal-fade-leave-to {
        opacity: 0;
    }
</style>
