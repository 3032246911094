import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

const heroSlider = {
    name: 'heroSlider',
    init() {
        const $sliders = document.querySelectorAll('.hero-slides');

        if ($sliders.length) {
            $sliders.forEach($slider => {
                const $slides = $slider.querySelectorAll('.hero');

                if ($slides.length > 1) {
                    new Flickity($slider, {
                        // options
                        cellSelector: '.hero',
                        pageDots: true,
                        adaptiveHeight: true,
                        freeScroll: true,
                        freeScrollFriction: 0.03,
                        setGallerySize: false,
                        on: {
                            ready: function() {
                              console.log('Flickity is ready');
                            },
                            change: function( index ) {
                              console.log( 'Slide changed to' + index );
                            }
                          }
                    });
                }
            });
        }
    },
};

export default heroSlider;