<template>
    <div>
        <div class="Blade Blade-gray" :class="{ 'Filter-hasDropdown': hasDropdown }">
            <div class="Filter_content">
                <div class="Filter_main">
                    <ul class="Filter_mainItems">
                        <li class="Filter_mainItem"
                            v-for="(tab, key) in visibleTabs"
                            :key="key"
                            @click="switchTab(tab.handle)"
                        >
                            <slot :name="tabHeadSlotName(tab.handle)">
                                <span class="Filter_mainItemLink cursor-pointer whitespace-no-wrap"
                                      :class="{ 'Filter_mainItemLink-active': tab.handle === activeTab }"
                                      v-text="tab.label"
                                />
                            </slot>
                        </li>
                    </ul>

                    <div v-if="hasDropdown" class="Filter_search">
                        <div class="Filter_select FormEl FormEl_select">
                            <div class="Filter_selectContainer SelectContainer">
                                <div class="SelectArrow"></div>
                                <select class="inputSelectTextareaCommon"
                                        @change.prevent.stop="switchTab($event.currentTarget.value)"
                                >
                                    <option v-for="(tab, key) in tabs"
                                            :value="tab.handle"
                                            :key="key"
                                            :selected="tab === activeTab"
                                            v-text="tab.label"
                                    />
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <slot :name="tabPanelSlotName" />
    </div>
</template>

<script>
export default {
    props: {
        tabs: { type: Array, required: true },
        hasDropdown: { type: Boolean, default: false },
    },

    data() {
        return {
            activeTab: null,
        };
    },

    mounted() {
        this.activeTab = this.tabs[0].handle;
    },

    computed: {
        /**
         * Show one element before the current element (if possible) and all elements after it.
         * Elements that don't fit in a single line will be wrapped; as the parent has a fixed height,
         * they'll be hidden.
         */
        visibleTabs() {
            const activeTabIndex = this.tabs.findIndex(tab => tab.handle === this.activeTab);
            return this.tabs.slice(activeTabIndex >= 1 ? activeTabIndex - 1 : 0);
        },

        tabPanelSlotName() {
            return `tab-panel-${this.activeTab}`;
        },
    },

    methods: {
        tabHeadSlotName(tabName) {
            return `tab-head-${tabName}`;
        },

        switchTab(tabName) {
            this.activeTab = tabName;
        },
    },
}
</script>

<style scoped>
</style>
