<template>
    <nav
        :aria-label="ariaLabels.navigation"
        :class="isFirstLevel ? 'header-overlay-mobile--first-level inset-x-0 bottom-0 bg-white': 'inset-0 bg-gray-100'"
        class="header-overlay-mobile fixed z-10 w-screen h-screen text-black"
    >
        <div v-if="!isFirstLevel" class="header-overlay-mobile__banner flex items-center justify-between border-b border-gray-200 px-6 bg-white">
            <button class="header-overlay-mobile__back-btn flex items-center focus:outline-none" @click="$emit('back')">
                {{ backButtonText }}
            </button>

            <button class="w-4 h-4 focus:outline-none" @click="$emit('close')" :aria-label="ariaLabels.close">
                <img src="~@/static/images/cross.svg" :alt="ariaLabels.close">
            </button>
        </div>

        <div class="header-overlay-mobile__menu flex flex-col overflow-auto">
            <slot></slot>
            <template v-for="(item, i) in items">
                <a
                    :key="i"
                    v-if="!item.children && !item.handle"
                    :href="item.link.url"
                    :target="item.link.target"
                    class="header-overlay-mobile__arrow-item p-6 border-b border-gray-200"
                >
                    <span class="header-overlay-mobile__menu-item flex-1 font-medium uppercase text-base text-black">
                        {{ item.link.label }}
                    </span>
                </a>

                <div
                    :key="i"
                    v-if="item.children && !item.handle"
                    class="flex border-b border-gray-200"
                >
                    <div
                        class="header-overlay-mobile__arrow-item p-6"
                        @click="currentOverlayId = i"
                    >
                        <div class="header-overlay-mobile__menu-item flex-1 font-medium uppercase text-base">{{ item.link.label }}</div>
                    </div>

                    <overlay-fade>
                        <header-overlay-mobile
                            v-if="currentOverlayId === i"
                            :items="item.children"
                            :is-first-level="false"
                            :aria-labels="ariaLabels"
                            :back-button-text="backButtonText"
                            @back="currentOverlayId = null"
                            @close="$emit('close')"
                        >
                            <p class="header-overlay-mobile__heading text-lg font-normal uppercase text-blue p-6">
                                {{ item.link.label }}
                            </p>
                        </header-overlay-mobile>
                    </overlay-fade>
                </div>

                <header-featured-link v-if="item.handle === 'featuredLink'" :link="item" :key="i"></header-featured-link>
                <header-link-list v-if="item.handle === 'linkList'" :links="item" :key="i"></header-link-list>
            </template>
        </div>
    </nav>
</template>

<script>
import HeaderFeaturedLink from "./HeaderFeaturedLink";
import HeaderLinkList from "./HeaderLinkList";
import OverlayFade from "../transitions/OverlayFade";

export default {
    name: "HeaderOverlayMobile",

    props: {
        isFirstLevel: {
            type: Boolean,
            default: true,
        },
        items: {
            type: Array,
            required: true,
        },
        ariaLabels: {
            type: Object,
            required: true,
        },
        backButtonText: {
            type: String,
            default: '',
        },
    },

    components: {
        HeaderFeaturedLink,
        HeaderLinkList,
        OverlayFade,
    },

    data() {
        return {
            currentOverlayId: null
        }
    },
}
</script>

<style scoped lang="scss">
    .header-overlay-mobile {
        &--first-level {
            top: 60px;
        }

        &__banner {
            height: 60px;
        }

        &__menu {
            height: calc(100vh - 60px);
        }

        &__arrow-item {
            @apply flex items-center justify-between w-full no-underline cursor-pointer;

            &::after {
                @apply bg-cover bg-center;
                content: '';
                width: 6px;
                height: 10px;
                background-image: url('~@/static/images/chevron-right-blue.svg');
            }
        }

        &__heading {
            letter-spacing: 2.93px;
        }

        &__menu-item {
            letter-spacing: 2.6px;
        }

        &__back-btn {
            &::before {
                content: '';
                @apply block bg-cover bg-center transform rotate-180 mr-2;
                width: 6px;
                height: 10px;
                background-image: url('~@/static/images/chevron-right-black.svg');
            }
        }
    }
</style>
