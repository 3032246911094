<template>
    <transition name="search-fade" mode="out-in">
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: "SearchFade"
    }
</script>

<style scoped lang="scss">
    .search-fade-enter-active {
        transition: opacity 200ms ease, transform 200ms ease;
    }
    .search-fade-enter {
        opacity: 0;
        transform: translateX(50px);
    }
</style>
