import "@/assets/styles/main.scss";
import "@/../src-legacy/styles/bootstrap.less";

// Import all vendor
// import 'vendor';

// Import all modules
import heroSlider from './assets/scripts/hero-slider';

// App main
const main = async () => {
    heroSlider.init();
};
main();

import Vue from "vue";
import CsrfInput from "@/app/components/CsrfInput";
import HeaderNav from "@/app/components/HeaderNav";
import YtVideo from "@/app/components/YtVideo";
import Modal from "@/app/components/Modal";
import ModalOpener from "@/app/components/ModalOpener";
import BackToTop from "@/app/components/BackToTop";
import Tabs from "@/app/components/Tabs";

new Vue({
    el: "#app",
    components: {
        CsrfInput,
        HeaderNav,
        YtVideo,
        Modal,
        ModalOpener,
        BackToTop,
        Tabs,
    },

    mounted() {
        window.dispatchEvent(new Event('VUE_APP_MOUNTED'));
    },
});
