<template>
    <div class="YtVideo relative aspect-ratio-16/9">
        <div v-if="!isTarteaucitronReady || (hasConsentBeenGiven && !isYoutubeJsApiLoaded)"
             class="flex items-center justify-center absolute inset-0"
        >
            <clip-loader :loading="true" color="#268df0" size="100px"></clip-loader>
        </div>
        <div v-else-if="!hasConsentBeenGiven">
            <div class="YtVideo__video-placeholder absolute inset-0"></div>
        </div>
        <div v-else>
            <iframe :src="videoUrl"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    frameborder="0"
                    class="absolute inset-0 w-full h-full"
            ></iframe>
        </div>
    </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader';

export default {
    name: 'YtVideo',

    components: { ClipLoader },

    props: {
        videoKey: { type: String, required: true },
        autoplay: { type: Boolean, default: false },
    },

    data () {
        return {
            isTarteaucitronReady: false,
            hasConsentBeenGiven:  false,
            isYoutubeJsApiLoaded: false,
        };
    },

    computed: {
        videoUrl () {
            const parameters = {
                autoplay:    1,
                controls:    1,
                rel:         0,
                showinfo:    0,
                enablejsapi: 1,
            };

            const queryString = Object.entries(parameters).map(entry => entry.join('=')).join('&')

            return `https://www.youtube.com/embed/${this.videoKey}?${queryString}`;
        },
    },

    mounted () {
        this.waitForTarteaucitron();
    },

    methods: {
        waitForTarteaucitron () {
            if (window.tarteaucitron) {
                this.onTarteaucitronReady();
                return;
            }

            // Event fired when the tarteaucitron.js youtubeapi service is added (regardless of whether or not the user has given its consent)
            document.addEventListener('youtubeapi_added', this.onTarteaucitronReady);
        },

        waitForConsent () {
            if (
                'tarteaucitron' in window
                && 'state' in window.tarteaucitron
                && 'youtubeapi' in window.tarteaucitron.state
                && window.tarteaucitron.state.youtubeapi
            ) {
                this.hasConsentBeenGiven = true;
                this.onConsentGiven();
                return;
            }

            // Event fired when the user gives its consent to the youtubeapi service
            document.addEventListener('youtubeapi_loaded', this.onConsentGiven);
        },

        waitForYoutubeApi () {
            if ('YT' in window) {
                this.isYoutubeJsApiLoaded = true;
                this.onYoutubeJsApiReady();
                return;
            }

            YoutubeService.subscribe(this.onYoutubeJsApiReady);
        },

        onTarteaucitronReady () {
            document.removeEventListener('youtubeapi_added', this.onTarteaucitronReady);

            // 💩 Required to ensure the tarteaucitron.js services are ready
            const intervalId = setInterval(() => {
                if (
                    window.tarteaucitron.services === undefined
                    || window.tarteaucitron.services.youtubeapi === undefined
                ) {
                    return false;
                }

                clearInterval(intervalId);
                this.isTarteaucitronReady = true;

                // Required to ensure the target element (.YtVideo__video-placeholder) has been added to the DOM
                this.$nextTick(() => {
                    window.tarteaucitron.fallback([ 'YtVideo__video-placeholder' ], function () {
                        return window.tarteaucitron.engage('youtubeapi');
                    });
                    this.waitForConsent();
                }, 100);
            });

        },

        onConsentGiven () {
            document.removeEventListener('youtubeapi_loaded', this.onConsentGiven);
            this.hasConsentBeenGiven = true;

            this.waitForYoutubeApi();
        },

        onYoutubeJsApiReady () {
            this.isYoutubeJsApiLoaded = true;
        },
    },
};
</script>

<style scoped>

</style>
