<template>
    <div class="header-featured-link mx-4 lg:mx-0 px-2 pt-6 pb-8 lg:p-0 border-t border-blue-lighter lg:border-0">
        <p class="header-featured-link__title font-medium text-base lg:text-lg text-black uppercase">{{ link.heading }}</p>
        <div class="header-featured-link__text text-sm lg:text-base text-black mt-2 lg:mt-4" v-html="link.text"/>
        <a class="header-featured-link__btn Button Button-ghost mt-4 lg:mt-6" :href="link.cta.url" :target="link.cta.target">{{ link.cta.label }}</a>
    </div>
</template>

<script>
export default {
    name: "HeaderFeaturedLink",

    props: {
        link: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style scoped lang="scss">
    .header-featured-link {
        &__title {
            letter-spacing: 0.14em;
        }

        &__text {
            line-height: 30px;
        }

        &__btn {
            &:hover {
                @apply border-blue bg-blue text-white;
            }
        }
    }
</style>
