<template>
    <div class="relative">
        <header
            :class="headerClasses"
            class="header-nav flex justify-between mx-auto border-b border-gray-200 transition-colors duration-200"
        >
            <a
                :href="logoLink.url"
                :target="logoLink.target"
                :class="isSearchOpened ? 'hidden lg:flex': 'flex'"
                class="header-nav__logo-container flex items-center justify-center px-1 lg:px-0 border-r border-gray-200"
            >
                <img :src="logo.url" :alt="logo.alt" class="header-nav__logo">
            </a>
            <nav v-if="!isSearchOpened" class="header-nav__nav flex flex-1" :aria-label="ariaLabels.navigation">
                <div v-if="isMobileMenuOpened && siteLinks.length" class="flex flex-1 flex-row-reverse justify-center items-center lg:hidden">
                    <a
                        v-for="(item, key) in siteLinks"
                        :href="item.url"
                        :key="key"
                        class="header-nav__site-link font-medium uppercase mx-2 text-gray-400 hover:text-black transition-colors duration-200"
                    >{{ item.text }}</a>
                </div>

                <ul class="hidden lg:flex list-none flex items-center px-6">
                    <li v-for="(item, key) in menu" :key="key" :class="{'ml-24': key+1 === menu.length}">
                        <button
                            v-if="item.children"
                            class="header-nav__menu-link text-black hover:text-blue-darker focus:text-blue-darker focus:outline-none mx-6 text-xs font-ubuntu font-medium uppercase block transition-colors duration-200"
                            @click="toggleDesktopSubmenu(key, $event)"
                        >
                            {{ item.link.label }}
                        </button>
                        <a
                            v-else
                            :href="item.link.url"
                            class="header-nav__menu-link block text-black mx-6 text-xs font-ubuntu font-medium uppercase"
                        >
                            {{ item.link.label }}
                        </a>
                    </li>
                </ul>
            </nav>

            <div :class="{'flex-1': isSearchOpened}" class="flex items-center px-4">
                <search-fade>
                    <form v-if="isSearchOpened" class="Header-nav__search-form flex flex-1" :action="searchUrl">
                        <button type="subtmit" :aria-label="ariaLabels.search" class="header-nav__search-btn"/>
                        <input
                            ref="inputSearch"
                            class="header-nav__search-input flex-1 bg-transparent px-4 focus:outline-none"
                            name="q"
                            type="search"
                            :placeholder="searchPlaceholder"
                            :value="searchRequest"
                        />
                    </form>
                </search-fade>

                <button
                    :class="{'header-nav__search-btn--opened': isSearchOpened}"
                    class="header-nav__search-btn focus:outline-none"
                    :aria-label="ariaLabels.openSearchField"
                    @click="toggleSearch"
                />
            </div>

            <div v-if="siteLinks.length" class="hidden lg:flex flex-row-reverse items-center lg:px-8 lg:border-l lg:border-gray-200">
                <a
                    v-for="(item, key) in siteLinks"
                    :href="item.url"
                    :key="key"
                    class="header-nav__site-link font-medium uppercase mx-2 text-gray-400 hover:text-black transition-colors duration-200"
                >{{ item.text }}</a>
            </div>
            <div class="flex lg:hidden justify-end items-center pr-6">
                <button
                    :class="{'header-nav__burger--opened': isMobileMenuOpened}"
                    class="header-nav__burger focus:outline-none"
                    :aria-label="ariaLabels.menu"
                    @click="toggleMobileMenu"
                />
            </div>
        </header>

        <menu-fade>
            <template v-for="(item, key) in menu">
                <nav v-if="isDesktopMenuOpened && item.children && currentSubmenuId === key" :key="key" class="header-nav__submenu hidden lg:block absolute inset-x-0 z-20 bg-gray-100" @click="desktopSubmenuClickHandler($event)">
                    <div class="header-nav__submenu-container mx-auto px-10 pt-5 pb-20">
                        <div class="flex flex-wrap -mx-2">
                            <template v-for="(element, i) in item.children">
                                <div class="w-1/5 p-2" :key="i">
                                    <header-featured-link v-if="element.handle === 'featuredLink'" :link="element"></header-featured-link>
                                    <header-link-list v-if="element.handle === 'linkList'" :links="element"></header-link-list>
                                </div>
                            </template>
                        </div>
                    </div>
                </nav>
            </template>
        </menu-fade>

        <overlay-fade>
            <header-overlay-mobile
                v-if="isMobileMenuOpened"
                class="lg:hidden"
                :items="menu"
                :aria-labels="ariaLabels"
                :back-button-text="backButtonText"
                @close="toggleMobileMenu"
            >
            </header-overlay-mobile>
        </overlay-fade>
    </div>
</template>

<script>
    import HeaderFeaturedLink from "./HeaderFeaturedLink";
    import HeaderLinkList from "./HeaderLinkList";
    import HeaderOverlayMobile from "./HeaderOverlayMobile";
    import OverlayFade from "../transitions/OverlayFade";
    import MenuFade from "../transitions/MenuFade";
    import SearchFade from "../transitions/SearchFade";

    export default {
        name: 'HeaderNav',

        props: {
            isTransparent: {
                type: Boolean,
                default: false,
            },
            logoLink: {
                type: Object,
                required: true,
            },
            logo: {
                type: Object,
                required: true,
            },
            menu: {
                type: Array,
                required: true,
            },
            siteLinks: {
                type: Array,
                required: true,
            },
            searchUrl: {
                type: String,
                required: true,
            },
            searchRequest: {
                type: String,
                required: true,
            },
            searchPlaceholder: {
                type: String,
                default: '',
            },
            backButtonText: {
                type: String,
                default: '',
            },
            ariaLabels: {
                type: Object,
                required: true,
            },
        },

        components: {
            HeaderFeaturedLink,
            HeaderLinkList,
            HeaderOverlayMobile,
            OverlayFade,
            MenuFade,
            SearchFade,
        },

        data() {
            return {
                isDesktopMenuOpened: false,
                isMobileMenuOpened: false,
                isSearchOpened: false,
                currentSubmenuId: 0,
            };
        },

        computed: {
            headerClasses() {
                if(this.isDesktopMenuOpened) {
                    return this.isTransparent ? 'absolute top-0 inset-x-0 z-10 bg-white': 'relative bg-white'
                }

                if(this.isMobileMenuOpened) {
                    return 'bg-white fixed top-0 inset-x-0 z-10';
                }

                return this.isTransparent ? 'header-nav--transparent absolute top-0 inset-x-0 z-10': 'relative bg-white';
            }
        },

        mounted() {
            window.addEventListener('click', this.windowClickHandler);
        },

        destroyed() {
            window.removeEventListener('click', this.windowClickHandler);
        },

        methods: {
            toggleDesktopSubmenu(key, event) {
                event.stopPropagation();

                if(this.isDesktopMenuOpened && this.currentSubmenuId === key) {
                    this.isDesktopMenuOpened = false;
                    return;
                }

                this.isDesktopMenuOpened = true;
                this.currentSubmenuId = key;
            },

            toggleMobileMenu() {
                this.isMobileMenuOpened = !this.isMobileMenuOpened;
                document.body.style.overflow = this.isMobileMenuOpened ? 'hidden': 'visible';
            },

            toggleSearch() {
                this.isSearchOpened = !this.isSearchOpened;

                if(this.isSearchOpened) {
                    this.$nextTick(() => {
                        this.$refs.inputSearch.focus();
                    });
                }
            },

            windowClickHandler() {
                this.isDesktopMenuOpened = false;
            },

            desktopSubmenuClickHandler(event) {
                event.stopPropagation();
            },
        },
    }
</script>

<style lang="scss" scoped>
    .header-nav {
        max-width: 1600px;
        height: 60px;

        @screen lg {
            height: 80px;
        }

        &--transparent {
            background-color: rgba(255, 255, 255, 0.9);
        }

        &__logo-container {
            @screen lg {
                width: 166px;
            }
        }

        &__logo {
            width: 84px;
        }

        &__burger {
            @apply bg-cover bg-center w-6;
            height: 15px;
            font-size: 0;
            background-image: url('~@/static/images/burger.svg');

            &--opened {
                width: 16px;
                height: 16px;
                background-image: url('~@/static/images/cross.svg');
            }
        }

        &__search-btn{
            @apply bg-cover bg-center w-6 h-6;
            font-size: 0;
            background-image: url('~@/static/images/search.svg');

            &--opened {
                width: 16px;
                height: 16px;
                background-image: url('~@/static/images/cross.svg');
            }
        }

        &__menu-link {
            letter-spacing: .14em;
        }

        &__submenu {
            top: 80px;
        }

        &__site-link {
            font-size: 0.8125rem;
        }
    }
</style>
