<template>
    <transition name="menu-fade" mode="out-in">
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: "MenuFade"
    }
</script>

<style scoped lang="scss">
    .menu-fade-enter-active, .menu-fade-leave-active {
        transition: opacity 150ms ease, transform 150ms ease;
    }
    .menu-fade-enter, .menu-fade-leave-to {
        opacity: 0;
        transform: translateY(-40px);
    }
</style>
